export const categoryIds = [
  8,
  9,
  10,
  11,
  12,
  13,
];

export const educationCategoryIds = [
  25,
  26,
];

export const featuredCategoryIds = [
  ...categoryIds,
  23,
  ...educationCategoryIds,
];

export const executiveCategoryIds = [
  23,
  24,
];

const mainCategoryIds = [
  27,
  36,
  ...featuredCategoryIds,
];

export const relatedCategoryIds = [
  ...mainCategoryIds,
  ...executiveCategoryIds,
  25,
];

export const pressCategoryIds = [
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  37,
];

export const newsFeedCategoryIds = [
  ...categoryIds,
  ...executiveCategoryIds,
  ...educationCategoryIds,
];

const notInNewsFeedCategoryIds = [
  38,
];

export const newsAndPressFeedCategoryIds = [
  ...categoryIds,
  ...executiveCategoryIds,
  ...educationCategoryIds,
  ...pressCategoryIds,
];

export const allNewsCategoryIds = [
  ...newsFeedCategoryIds,
  ...pressCategoryIds,
];

export const allCategoryIds = [
  ...newsFeedCategoryIds,
  ...pressCategoryIds,
  ...notInNewsFeedCategoryIds,
  27,
  36,
];

export default mainCategoryIds;
